import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe, Container } from 'unstated'

import debounce from 'lodash/debounce'

import api from 'utils/api'
import logger from 'utils/logger'

class AirportsContainer extends Container {
  constructor (props) {
    super(props)

    this.debouncedLoad = debounce(this.load, 500, {
      trailing: true,
      leading: false
    })

    this.state = {
      list: [],
      page: 1,
      loading: false,
      hasMore: true,
      term: '',
      error: null
    }
  }

  load = async () => {
    logger.captureBreadcrumb({
      message: 'AirportsContainer.load',
      category: 'airports'
    })

    this.setState({ loading: true })

    const { page, term } = this.state

    try {
      const [error, data] = await getAirports({ page, term })

      if (error) {
        console.log(error)
        logger.captureException(error)

        this.setState({ loading: false, error })
        return
      }

      const totalPages = Math.ceil(data.totalAirports / data.perPageCount)
      const hasMore = data.currentPage < totalPages

      let list = data.airports

      if (page > 1) {
        list = this.state.list.concat(list)
      }

      this.setState({ loading: false, list, hasMore })
    } catch (error) {
      console.log(error)
      logger.captureException(error)
    }
  }

  loadNext = async () => {
    const { page, hasMore } = this.state
    if (hasMore) {
      await this.setState({ page: page + 1 })
      await this.load()
    }
  }

  search = async term => {
    await this.setState({ term, page: 1 })
    await this.debouncedLoad()
  }
}

export default class AirportsProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new AirportsContainer()
  }

  componentDidMount () {
    this.container.load()
  }

  render () {
    return (
      <Subscribe to={[this.container]}>
        {store => this.props.children(store)}
      </Subscribe>
    )
  }
}

export const getAirports = async ({ page, term }) => {
  const params = {
    page,
    term,
    filter: 'only_activeod'
  }

  return api.get('/airports', params)
}
