import React from 'react'

import Helmet from 'react-helmet'
import get from 'lodash/get'

import styled from 'styled-components'

import Layout from 'components/Layout'
import Icon from 'components/Icon'
import Loader from 'components/Loader'
import SectionsView from 'components/Landings/SectionsView'
import Footer from 'components/Landings/Footer'

import whenScrolledIntoView from 'utils/whenScrolledIntoView'

import AirportsProvider from './_store'

const AirportsPage = () => (
  <Layout className='overflow-x-hidden' style={{ background: '#F7FAFC' }}>
    <Helmet>
      <title>Airports Launched on Adventure Machine</title>
    </Helmet>

    <Hero>
      <Container>
        <Logo src='https://res.cloudinary.com/staycircles/image/upload/v1555000229/assets/branding/am-logo-by-sc.png' />
        <h1>Airports launched around the world</h1>
      </Container>
    </Hero>

    <AirportsProvider>
      {store => (
        <Container className='ph5' style={{ marginTop: -30 }}>
          <SearchBar onChange={store.search} />

          {store.state.list.map(item => (
            <AirportItem key={item.id} {...item} />
          ))}

          {store.state.loading && (
            <div className='h3 tc mt5 pb6 mb7'>
              <Loader width='25px' height='35px' />
            </div>
          )}

          {!store.state.loading &&
            store.state.hasMore && (
            <div className='flex flex-row justify-center'>
              <LoadMoreButton onClick={store.loadNext} />
            </div>
          )}
        </Container>
      )}
    </AirportsProvider>

    <SectionsView sections={['adventure', 'business']} />
    <Footer createAccount business friendship adventure marginTop />
  </Layout>
)

const SearchBar = ({ onChange }) => (
  <ItemRow
    className='justify-center items-center'
    as='label'
    htmlFor='search-airport'
  >
    <Icon name='search' height={20} color='#B4C3D4' />
    <SearchInput
      id='search-airport'
      type='text'
      placeholder='Search city'
      onChange={e => {
        onChange(e.target.value)
      }}
    />
  </ItemRow>
)

const getRegionName = ({ regionalCity, country }) => {
  let name

  if (get(regionalCity, 'name')) {
    name = get(regionalCity, 'name')
  }

  if (name && get(country, 'iso')) {
    name = `${name}, ${get(country, 'iso')}`
  } else if (get(country, 'name')) {
    name = get(country, 'name')
  }

  return name
}

const AirportItem = props => {
  return (
    <ItemRow>
      <div className='flex flex-column w-100'>
        <AirportName className='truncate'>
          {props.iataCode} - {props.name}
        </AirportName>
        <div className='flex flex-row items-baseline'>
          <AirportLocation>{getRegionName(props)}</AirportLocation>
          <Badges {...props} />
        </div>
      </div>
    </ItemRow>
  )
}

const Badges = ({ activeOdBusinessAndFirst, activeOdEconomy }) => {
  let badges = [
    {
      label: 'Economy',
      color: '#1e78e5',
      bg: '#deedff',
      active: activeOdEconomy
    },
    {
      label: 'First Class',
      color: '#52A374',
      bg: '#E4F0E9',
      active: activeOdBusinessAndFirst
    },
    {
      label: 'Soon',
      color: '#737373',
      bg: '#E5E5E5',
      active: !activeOdEconomy && !activeOdBusinessAndFirst
    }
  ]

  return badges.filter(b => b.active).map(badge => (
    <Badge bg={badge.bg} color={badge.color} key={badge.label}>
      <span>{badge.label}</span>
    </Badge>
  ))
}

class LoadMoreButton extends React.Component {
  componentDidMount () {
    this.clearListener = whenScrolledIntoView(this.button, this.props.onClick)
  }

  componentWillUnmount () {
    this.clearListener()
  }

  render () {
    const { onClick } = this.props
    return (
      <button
        ref={node => {
          this.button = node
        }}
        type='button'
        onClick={onClick}
        className='bg-transparent link pointer underline-hover tc bn b pv4'
        style={{ color: '#53a0ff' }}
      >
        Load more...
      </button>
    )
  }
}

const Container = styled.div`
  width: 660px;
  margin: 0 auto;
`

const Hero = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
    font-size: 54px;
    text-align: center;
    margin: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  background: url('https://res.cloudinary.com/staycircles/image/upload/c_scale,w_900/assets/airports_launched/map.png')
    no-repeat 30% center;
  background-size: cover;

  @media (min-width: 1200px) {
    background: url('https://res.cloudinary.com/staycircles/image/upload/c_scale,w_1920/assets/airports_launched/map.png')
      no-repeat center center;
    background-size: cover;
  }

  @media (min-width: 2000px) {
    background: url('https://res.cloudinary.com/staycircles/image/upload/assets/airports_launched/map.png')
      no-repeat center center;
    background-size: cover;
  }
`

const Logo = styled.img`
  width: 266px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 54px;
`

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &:focus-within {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }
`

const SearchInput = styled.input`
  width: 100%;
  font-size: 18px;
  padding-left: 16px;
  border: none;

  &::placeholder {
    opacity: 1;
    font-size: 18px;
    color: #7e8b9a;
  }

  &:focus {
    outline: none;
  }
`

const AirportName = styled.span`
  font-size: 16px;
  color: #11345f;
  font-weight: 600;
  margin-bottom: 10px;
`

const AirportLocation = styled.span`
  font-size: 16px;
  color: #7e8b9a;
`

const Badge = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  color: ${props => props.color};
  background: ${props => props.bg};
  padding: 3px 8px;
  border-radius: 20px;
  margin-left: 10px;
`

export default AirportsPage
