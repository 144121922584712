import root from 'utils/windowOrGlobal'
import throttle from 'lodash/throttle'

export default function whenScrolledIntoView (el, callback) {
  const checkIfScrolledIntoView = throttle(
    () => {
      if (isScrolledIntoView(el)) {
        removeListener()
        callback()
      }
    },
    250,
    {
      trailing: false,
      leading: true
    }
  )

  function removeListener () {
    root.removeEventListener('scroll', checkIfScrolledIntoView)
  }

  root.addEventListener('scroll', checkIfScrolledIntoView)

  return removeListener
}

export function isScrolledIntoView (el) {
  var bounding = el.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (root.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (root.innerWidth || document.documentElement.clientWidth)
  )
}
